<template>
  <div>
    <template>
      <a-space>
        <a-range-picker @change="handleDateChange" />
        <a-button type="primary" @click="downloadExcel"
          >Download Excel</a-button
        >
      </a-space>
    </template>


    <a-table
      :columns="columns"
      :data-source="transformedData"
      :row-key="getRowKey"
      bordered
    ></a-table>
    <!-- <a-button type="primary" @click="downloadExcel">Download Excel</a-button> -->
  </div>
</template>

<script>
import { Button, Table, DatePicker } from "ant-design-vue";
import { utils, writeFileXLSX } from "xlsx";
import { sendRequest } from "@/http/axios.method";
export default {
  components: {
    "a-button": Button,
    "a-table": Table,
    "a-date-picker": DatePicker,
  },
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      confirmLoading: false,
      dataSource: [],
      columns: [],
      fromDate: null,
      toDate: null,
      transformedData: [],
    };
  },
  mounted() {
    this.dashboardReport();
  },
  methods: {
    handleDateChange(dates) {
      const [fromDate, toDate] = dates;
      this.fromDate = fromDate.format("YYYY-MM-DD");
      this.toDate = toDate.format("YYYY-MM-DD");
      this.dashboardReport();
    },
    dashboardReport() {
      this.confirmLoading = true;
      let from_date = this.fromDate ? this.fromDate : "";
      let to_date = this.toDate ? this.toDate : "";

      // from_date = "2023-06-13";
      // to_date = "2023-06-15";
      let form_data = new FormData();

      form_data.append("from_date", from_date);
      form_data.append("to_date", to_date);
      // console.log(form_data);
      sendRequest("post", "admin/get-event-date-user", form_data, this.config)
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            let data = response.data;
            this.dataSource = data;
            // Extract unique dates and event names
            // Extract unique dates and event names
            const uniqueDates = [
              ...new Set(this.dataSource.map((item) => item.date)),
            ];
            const uniqueEventNames = [
              ...new Set(this.dataSource.map((item) => item.event_name)),
            ];

            // Prepare columns
            this.columns = [
              { title: "Date", dataIndex: "date", key: "date" },
              ...uniqueEventNames.map((eventName) => ({
                title: eventName,
                dataIndex: eventName,
                key: eventName,
              })),
            ];

            // Prepare transformed data
            this.transformedData = this.transformDataSource(
              uniqueDates,
              uniqueEventNames
            );
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
            if (response.status_code === 500) {
              this.revenueReportData = [];
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    transformDataSource(uniqueDates, uniqueEventNames) {
      const transformedData = [];

      uniqueDates.forEach((date) => {
        const eventData = { date };

        uniqueEventNames.forEach((eventName) => {
          const event = this.dataSource.find(
            (item) => item.event_name === eventName && item.date === date
          );
          eventData[eventName] = event ? event.count : 0;
        });

        transformedData.push(eventData);
      });

      return transformedData;
    },
    getRowKey(record) {
      return record.date; // Use date as the unique key for each record
    },
    downloadExcel() {
      const worksheet = utils.json_to_sheet(this.transformedData);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Data");
      writeFileXLSX(workbook, "data.xlsx");
    },
  },
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
